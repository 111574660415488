exports.components = {
  "component---src-templates-about-us-tsx": () => import("./../../../src/templates/about-us.tsx" /* webpackChunkName: "component---src-templates-about-us-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-expertise-listing-tsx": () => import("./../../../src/templates/expertise-listing.tsx" /* webpackChunkName: "component---src-templates-expertise-listing-tsx" */),
  "component---src-templates-expertise-tsx": () => import("./../../../src/templates/expertise.tsx" /* webpackChunkName: "component---src-templates-expertise-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-people-tsx": () => import("./../../../src/templates/people.tsx" /* webpackChunkName: "component---src-templates-people-tsx" */),
  "component---src-templates-person-tsx": () => import("./../../../src/templates/person.tsx" /* webpackChunkName: "component---src-templates-person-tsx" */),
  "component---src-templates-single-news-tsx": () => import("./../../../src/templates/single-news.tsx" /* webpackChunkName: "component---src-templates-single-news-tsx" */)
}

